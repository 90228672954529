import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../multiCarousel/multiCar.css";
//import DisplayItem from "../multiCarousel/DisplayItem";
import ModalWinner from "../multiCarousel/ModalWinner.js";
//import ModalSiteMap from "../multiCarousel/ModalSiteMap.js";

//import '/home/home.css';
/*import { BsInfoSquareFill } from "react-icons/bs";*/
/*import { FaCentercode } from "react-icons/fa";*/
/*import {MCHome4} from '../multiCarousel/multiCar.css';*/

function MCHome1() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  //const [modalSiteMap, setModalSiteMap] = useState(false);

  /*<div className="mc1cliktoHelp"
        onClick={() => navigate('/support')}>
        <div>
          <p className="home-hours-text">Plan Your Visit</p>
        </div>
      </div>*/
  /*<div className="mc1DsplyItem" onClick={() => setModalSiteMap(true)}>
        <div>
          <p>testing</p>
        </div>
      </div>

      <div>
        {modalSiteMap && <ModalSiteMap setOpenModalSiteMap={setModalSiteMap} />}
      </div>*/
  /*<div className="mc1DsplyItem" onClick={() => setModalOpen(true)}>
        <div>
          <p className="homeDsplyItem">WE ARE HIRING A BAKER!</p>
        </div>
      </div>*/
  return (
    <div className="entranceCntnr">
      <div className="ffdRow">
        <div className="eventCol">
          <img
            className="eventImg"
            style={{ marginBottom: "0px" }}
            src={process.env.PUBLIC_URL + "/events/" + "LFCF_poster2a.png"}
            alt="Johnny  Cash event"
          ></img>
        </div>
      </div>
      <div>{modalOpen && <ModalWinner setOpenModal={setModalOpen} />}</div>

      <div className="mc1cliktoEvents" onClick={() => navigate("/support")}>
        <div>
          <p className="home-hours-text">PLAN YOUR VISIT</p>
        </div>
      </div>
    </div>
  );
}

export default MCHome1;
